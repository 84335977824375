
















































import { PostStore } from '@/stores/post-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'rct-post-pin-action': () => import('@/modules/recruitment/posts/components/rct-post-pin-action.vue'),
    'rct-status': () => import('@/modules/recruitment/posts/components/rct-status.vue'),
  },
})
export default class extends Vue {
  @Prop({}) postStore!: PostStore
  @Prop({ default: false }) enableBlind!: boolean
  @Prop({ default: false }) isRctAdmin
}
