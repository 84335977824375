import { render, staticRenderFns } from "./rct-post-pin-header.vue?vue&type=template&id=8af5e144&scoped=true&"
import script from "./rct-post-pin-header.vue?vue&type=script&lang=ts&"
export * from "./rct-post-pin-header.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8af5e144",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
